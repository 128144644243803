<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(result)">
          <div class="con_ttl center">
            <span>충전 완료</span>
          </div>
          <div class="lump bo_zero">
            <div class="table_type01">
              <dl>
                <dt>충전일시</dt>
                <dd>
                  {{ result.paymentDate }}
                </dd>
              </dl>
              <dl>
                <dt>충전금액</dt>
                <dd>
                  {{ $Util.formatNumber2(result.price, { unit: '원' }) }}
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>상품명</dt>
                <dd>위즈 캐시 충전</dd>
              </dl>
              <dl>
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name">{{ userData.name }}</span>
                  <span>{{ userData.nick }}</span>
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          Number(userData.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.value
                      },
                      {
                        naver:
                          Number(userData.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.value
                      },
                      {
                        google:
                          Number(userData.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.value
                      },
                      {
                        apple:
                          Number(userData.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.value
                      }
                    ]"
                    v-if="!$Util.isEmpty(userData.login_type)"
                  >
                    {{ $ConstCode.getLoginType(userData.login_type).enName }}
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>총 결제액</dt>
                <dd class="space_between">
                  <div class="total">
                    <span class="sec_color">{{
                      $Util.formatNumber2(result.price + result.fee, {
                        unit: ''
                      })
                    }}</span>
                    원
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>충전금액</dt>
                <dd class="space_between">
                  <div class="total">
                    <span class="sec_color">{{
                      $Util.formatNumber2(result.price, { unit: '' })
                    }}</span>
                    원
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="con_btn" style="margin-top: 0;">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnAdjustment">
                충전내역 조회
              </button>
              <button class="btn" @click="fnGoBack">
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'my-page-cash-charge-done-index',
  setup() {
    return { ...componentState() }
  }
}
</script>
