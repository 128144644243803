import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CommonFunction } from '@/common/CommonFunction'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    poid: route.query.poid,
    result: {},
    previousRoutes: computed(() => getters['previousRoute'])
  })

  const { copyCliboard } = CommonFunction(state)

  const fnResult = async () => {
    const res = await proxy.$CashSvc.postCashCargeResult(route.query)
    if (res.resultCode === '0000') {
      state.result = res
    } else {
      alert(res.resultMsg)
    }
  }
  const fnAdjustment = () => router.push({ name: 'my-page-adjustment-index' })

  const fnGoBack = () => {
    let preR
    if (state.previousRoutes.length > 3) {
      preR = state.previousRoutes.splice(-3)
    } else {
      preR = [...state.previousRoutes]
    }

    if (!proxy.$Util.isEmpty(preR)) {
      const chargePage = preR.find(r => r.name === 'my-page-cash-charge-index')
      if (chargePage && !proxy.$Util.isEmpty(chargePage.query)) {
        return router.push({
          name: chargePage.query.from,
          query: { ...chargePage.query }
        })
      } else {
        return router.push({ name: 'my-page-adjustment-index' })
      }
    } else {
      return router.push({ name: 'my-page-adjustment-index' })
    }
  }

  const init = async () => {
    await fnResult()
  }
  init()
  return {
    ...toRefs(state),
    copyCliboard,
    fnAdjustment,
    fnGoBack
  }
}
